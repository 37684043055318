document.addEventListener('DOMContentLoaded', () => {

  const anchors_link = document.querySelectorAll('.anchor-nav-link');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: .5
  };

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        anchors_link.forEach(anchor_link => {
          if (anchor_link.getAttribute('href') == '#'+entry.target.getAttribute('id')) {
            anchor_link.classList.add('active');
          } else {
            anchor_link.classList.remove('active');
          }
        });

      }
    });
  }, options);

  // get all links & for each link
  document.querySelectorAll('.anchor-section').forEach(anchor => {
    // start the mutation observer
    observer.observe(anchor);
  });
});
