<template>
  <form class="account-form" @submit.prevent="saveAccount()">
    <div class="form-group form-group-required">
      <label class="form-label" for="company">{{ $t('components.account_form.company') }}</label>
      <input
        id="company"
        v-model="account.recruiter.company"
        type="text"
        class="form-control"
        name="company"
        :class="{ 'is-invalid': account.recruiter.company && v.account.recruiter.company.$invalid }">
      <div
        v-if="account.recruiter.company && !v.account.recruiter.company.required"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
    </div>

    <div class="form-group form-group-required">
      <label class="form-label" :for="'address_content'">{{ $t('components.register_information.address.label') }}</label>
      <VueBootstrapAutocomplete
        id="address_content"
        v-model="addressQuery"
        :max-matches="5"
        class="mb-1"
        :show-all-results="true"
        :data="mapboxAddresses"
        :serializer="item => item.place_name"
        placeholder="{{ $t('components.register_information.address.placeholder') }}"
        @hit="onSelectAddress($event)"
        @input="lookupAddressHandler"
      />
    </div>

    <div v-if="isHeadHunter && localAddress" class="form-group form-group-required">
      <v-activity-sector-field
        :address="localAddress"
        :index="0"
        @on-select-activity-sector="onSelectActivitySector"
      />
    </div>

    <div v-if="!isHeadHunter" class="form-group form-group-required">
      <label class="form-label" for="company">{{ $t('components.register_information.manager.label') }}</label>
      <input
        id="manager"
        v-model="account.recruiter.manager"
        type="text"
        class="form-control"
        name="manager"
        :class="{ 'is-invalid': account.recruiter.manager && v.account.recruiter.manager.$invalid }">
      <div
        v-if="account.recruiter.manager && !v.account.recruiter.manager.required"
        data-cy="account-manager-required-invalid-feedback"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>

      <div
        v-if="account.recruiter.manager && !v.account.recruiter.manager.hasFullName"
        data-cy="account-manager-full-name-invalid-feedback"
        class="invalid-feedback">
        {{ $t('form.validation.full_name') }}
      </div>
    </div>

    <div class="form-group">
      <label for="uploadFile" class="form-label d-block">
        <div class="form-label">{{ $t('components.register_address.logo') }}</div>
        <v-file-indicator :file="selectedFile">
          <div class="mt-2">
            <small class="form-text steel-text">
              {{ $t('form.image.square') }} <br>
              {{ $t('form.image.format') }} <br>
              {{ $t('form.image.max_size') }}
            </small>
          </div>
        </v-file-indicator>
      </label>
      <input id="uploadFile" type="file" class="form-control visually-hidden" @change="onFileChange">
    </div>

    <div class="form-group">
      <button id="account-form-btn" type="submit" class="btn btn-primary btn-rounded" :disabled="v.account.$invalid">
        {{ $t('actions.save') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  cloneDeep, throttle,
} from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import VueBootstrapAutocomplete from 'vue3-bootstrap-autocomplete';
import { mapActions, mapGetters, mapState } from 'vuex';

import { useUploadImage } from '@/js/composition/uploadImage';
import ActivitySector from '@/js/interfaces/activity-sector';
import Address from '@/js/interfaces/address';
import MapboxAddress from '@/js/interfaces/mapbox-address';
import Recruiter from '@/js/interfaces/recruiter';
import { searchMapboxAddress } from '@/js/services/mapbox';
import { hasFullName } from '@/js/services/utils';

interface PartialAddressPayload {
  uuid: string;
  activitySector: ActivitySector['id'];
}

interface Account {
  recruiter: {
    company: string;
    manager?: string;
    address: MapboxAddress;
  };
}

export default defineComponent({
  name: 'AccountForm',
  components: { VueBootstrapAutocomplete },
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    const { selectedFile, logo, onFileChange } = useUploadImage();
    return {
      toast, v, selectedFile, logo, onFileChange,
    };
  },
  data() {
    return {
      account: {
        recruiter: {
          company: '',
          manager: '',
          address: {},
        },
      } as Account,
      mapboxAddress: {} as MapboxAddress,
      mapboxAddresses: [] as MapboxAddress[],
      addressQuery: '',
      localAddress: null as Address | null,
      partialAddressPayload: null as PartialAddressPayload | null,

    };
  },
  validations() {
    const validations = {
      account: {
        recruiter: {
          company: { required },
          address: { required },
          manager: {},
        },
      },
    };

    if (!this.isHeadHunter) {
      validations.account.recruiter.manager = { required, hasFullName };
    }

    return validations;
  },
  computed: {
    ...mapState('recruiter', [
      'user',
      'parentAddress',
      'currentAddress',
    ]),
    ...mapGetters('recruiter', [
      'isHeadHunter',
    ]),
  },
  async mounted(): Promise<void> {
    this.account = {
      recruiter: {
        company: this.user.recruiter.company,
        manager: this.user.recruiter.manager,
        address: this.user.recruiter.address,
      },
    };

    this.localAddress = cloneDeep(this.currentAddress);
    this.addressQuery = this.account.recruiter.address?.placeName ?? '';
  },
  created() {
    this.lookupAddressHandler = throttle(this.searchAddress, 1000);
  },
  unmounted() {
    this.lookupAddressHandler.cancel();
  },
  methods: {
    ...mapActions('recruiter', [
      'setUser',
      'getProfile',
      'getAddresses',
      'setCurrentAddress',
    ]),

    /**
     * Search for an address
     */
    async searchAddress(): Promise<void> {
      if (this.addressQuery && this.addressQuery.length > 2) {
        this.mapboxAddress = {} as MapboxAddress;
        this.account.recruiter.address = this.mapboxAddress;
        const data = await searchMapboxAddress(this.addressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },

    /**
     * Method called when the user selects an activity sector
     * @param activitySector
     */
    async onSelectActivitySector(activitySector: ActivitySector): Promise<void> {
      if (!this.localAddress) {
        return;
      }

      this.localAddress.activitySector = activitySector;
    },

    /**
     * Method called when the user selects an address
     * @param selectedAddress
     */
    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.addressQuery = selectedAddress.place_name;
      this.mapboxAddress = selectedAddress;
      this.mapboxAddresses = [];
      this.account.recruiter.address = this.mapboxAddress;
    },

    /**
     * Update the logo of the recruiter
     */
    async updateLogo(): Promise<void> {
      if (!this.logo || !this.logo.startsWith('data:image/')) {
        return;
      }

      const payload = {
        logo: this.logo,
      };

      const data = await window.ky.patch(`/api/addresses/${this.localAddress?.uuid}/`, { json: payload }).json<Address>();
      await this.setCurrentAddress(data);
    },

    /**
     * Save the account
     */
    async saveAccount(): Promise<void> {
      try {
        const payload = cloneDeep(this.account);

        if (this.user.recruiter.headhunter) {
          delete payload.recruiter.manager;
        }

        const data = await window.ky.patch('/api/recruiter/me/', { json: payload }).json<Recruiter>();
        await this.getProfile();

        const user = cloneDeep(this.user);
        user.recruiter.manager = data.recruiter.manager;
        user.recruiter.address = data.recruiter.address;
        user.recruiter.company = data.recruiter.company;

        await this.setUser(user);
        await this.updateAddress();
        await this.updateLogo();
        this.toast.success((this.$t('components.account_form.success') as string));
      } catch (error) {
        console.error(error);
        this.toast.error((this.$t('components.account_form.error') as string));
      }
    },

    /**
     * Update the address
     */
    async updateAddress(): Promise<void> {
      await window.ky.patch(`/api/addresses/${this.localAddress?.uuid}/`, {
        json: {
          activitySector: this.localAddress?.activitySector.id,
        },
      }).json<Address>();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form-label {
  font-weight: 500;

  .file-upload {
    font-weight: 400;
  }
}

.account-form {
  max-width: 100%;
  width: 500px;

  .file-upload {
    display: block;
  }
}

.btn-primary {
  width: 100%;

  @include mq($from: mobile) {
    width: auto;
  }
}
</style>
