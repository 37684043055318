import saveAs from 'file-saver';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toast-notification';
import { useStore } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export function useQrCode() {
  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();
  const currentAddress = computed(() => store.state.recruiter.currentAddress);
  const parentAddress = computed(() => store.state.recruiter.parentAddress);
  const isPremium = computed(() => store.getters['recruiter/isPremium']);

  const qrCodeUuuid = computed(() => {
    if (isPremium.value) {
      return parentAddress.value.uuid;
    }

    return currentAddress.value?.uuid;
  });

  const getQrCodeBlob = async (rawQrCode = true): Promise<[Blob, string]> => {
    const addressEndPoint = rawQrCode ? 'qrcode_small' : 'qrcode';

    const response = await window.ky.get(`/api/addresses/${qrCodeUuuid.value}/${addressEndPoint}/`);
    const fileNameHeader = 'x-suggested-filename';
    const suggestedFileName = response.headers[fileNameHeader];
    const blob = await response.blob();
    return [
      blob,
      suggestedFileName,
    ];
  };
  const downloadQrCode = async (rawQrCode = true): Promise<void> => {
    if (!currentAddress.value) {
      return;
    }

    try {
      const [blob, suggestedFileName] = await getQrCodeBlob(rawQrCode);
      saveAs(blob, suggestedFileName);
    } catch {
      toast.error((t('components.store_switch.qr_code_download.error') as string));
    }
  };
  const printQrCode = async (rawQrCode = true): Promise<void> => {
    if (!currentAddress.value) {
      return;
    }

    try {
      const [blob] = await getQrCodeBlob(rawQrCode);
      const hideFrame = document.createElement('iframe');

      // If the frame was already created, remove it
      const existingFrame = document.querySelector('#printFrame');
      if (existingFrame) {
        existingFrame.remove();
      }

      hideFrame.id = 'printFrame';
      hideFrame.addEventListener('load', () => {
        const printFrame = document.querySelector('#printFrame') as HTMLIFrameElement | null;
        if (printFrame?.contentWindow) printFrame.contentWindow.print();
      });
      hideFrame.style.display = 'none';
      hideFrame.src = URL.createObjectURL(blob);
      document.body.append(hideFrame);
    } catch {
      toast.error((t('components.store_switch.qr_code_print.error') as string));
    }
  };
  return {
    printQrCode,
    getQrCodeBlob,
    qrCodeUuuid,
    downloadQrCode,
  };
}
