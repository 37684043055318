class JobSearch {
  suggestedJobs: Array<string>;

  constructor() {
    this.suggestedJobs = [];
  }

  buildDropdown() {
    const jobs = this.suggestedJobs;
    const dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');
    dropdown.innerHTML = `
      <ul class="" aria-labelledby="dropdownMenuButton">
        ${jobs.map((job) => `<li><a class="dropdown-item" href="/app/register/1/?job=${job.name}">${job.Formatted.name}</a></li>`).join('')}
      </ul>
    `;
    return dropdown;
  }

  emptyDropdown() {
    const results = document.querySelector('#job-search-form-results');
    if (results) {
      results.innerHTML = '';
      this.suggestedJobs = [];
    }
  }

  handleJobSearch() {
    const jobSearchInput = document.querySelector('#job-search-form-query') as HTMLInputElement;

    if (jobSearchInput) {
      jobSearchInput.addEventListener('keyup', (event) => {
        event.preventDefault();
        const query = event?.target?.value;

        if (query.length < 3) {
          this.emptyDropdown();
          return;
        }

        const url = `/api/jobs/search/?term=${query}`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.hits.length === 0) {
              this.emptyDropdown();
              return;
            }

            this.suggestedJobs = data.hits.slice(0, 5);

            const dropdown = this.buildDropdown();
            const results = document.querySelector('#job-search-form-results');

            if (results) {
              results.innerHTML = '';
              results.append(dropdown);
            }
          });
      });
    }
  }
}

window.addEventListener('load', () => {
  if (document.querySelector('#job-search-form-query')) {
    const jobSearch = new JobSearch();
    jobSearch.handleJobSearch();
  }
});
