import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toast-notification';

import Licence from '@/js/interfaces/licence';

import Caces from '../interfaces/caces';
import Experience from '../interfaces/experience';
import Language from '../interfaces/language';
import Locomotion from '../interfaces/locomotion';
import { Skill, SkillCategory } from '../interfaces/skill';

const PERSONAL_QUALITY_SKILL_CATEGORY_ID = Number.parseInt(process.env.PERSONAL_QUALITY_SKILL_CATEGORY_ID ?? '0', 10);
const PROFESSIONAL_KNOWLEDGE_SKILL_CATEGORY_ID = Number.parseInt(process.env.PROFESSIONAL_KNOWLEDGE_SKILL_CATEGORY_ID ?? '0', 10);
const PROFESSIONAL_KNOWLEDGE_SOFT_SKILL_CATEGORY_ID = Number.parseInt(process.env.PROFESSIONAL_KNOWLEDGE_SOFT_SKILL_CATEGORY_ID ?? '0', 10);

// eslint-disable-next-line import/prefer-default-export
export function useJobSkill() {
  const experiences = ref<Experience[]>([]);
  const personalQualities = ref<Skill[]>([]);
  const professionalKnowledge = ref<Skill[]>([]);
  const professionalSoftSkills = ref<Skill[]>([]);
  const languages = ref<Language[]>([]);
  const locomotions = ref<Locomotion[]>([]);
  const licences = ref<Licence[]>([]);
  const caces = ref<Caces[]>([]);

  const toast = useToast();
  const { t } = useI18n();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getJobSkills = async (): Promise<void> => {
    try {
      const skills: SkillCategory[] = await window.ky.get('/api/skills/').json<SkillCategory[]>();
      personalQualities.value = skills.find(
        ((skill: SkillCategory) => skill.id === PERSONAL_QUALITY_SKILL_CATEGORY_ID),
      )?.skillSet as Skill[];

      professionalSoftSkills.value = skills.find(
        ((skill: SkillCategory) => skill.id === PROFESSIONAL_KNOWLEDGE_SOFT_SKILL_CATEGORY_ID),
      )?.skillSet as Skill[];

      professionalKnowledge.value = skills.find(
        (skill: SkillCategory) => skill.id === PROFESSIONAL_KNOWLEDGE_SKILL_CATEGORY_ID,
      )?.children?.flatMap((skillCategory: SkillCategory) => skillCategory.skillSet) || [];
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getExperiences = async (): Promise<void> => {
    try {
      experiences.value = await window.ky.get('/api/experiences/').json<Experience[]>();
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getLanguages = async (): Promise<void> => {
    try {
      const response = await window.ky.get('/api/languages/').json<Language[]>();
      languages.value = response.results as Language[];
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getLocomotions = async (): Promise<void> => {
    try {
      const response = await window.ky.get('/api/locomotions/').json<Locomotion[]>();
      locomotions.value = response.results as Locomotion[];
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };
    // eslint-disable-next-line unicorn/consistent-function-scoping
  const getLicences = async (): Promise<void> => {
    try {
      const response = await window.ky.get('/api/licences/').json<Licence[]>();
      licences.value = response.results as Licence[];
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getCaces = async (): Promise<void> => {
    try {
      const response = await window.ky.get('/api/caces/').json<Caces[]>();
      caces.value = response.results as Caces[];
    } catch {
      toast.error((t('views.resume_filters.error') as string));
    }
  };
  return {
    experiences,
    licences,
    languages,
    locomotions,
    getCaces,
    getLicences,
    getLanguages,
    getJobSkills,
    getExperiences,
    getLocomotions,
    caces,
    personalQualities,
    professionalKnowledge,
    professionalSoftSkills,
  };
}
