<template>
  <form
    v-if="action.name === actionTypesType.experience && action.active"
    class="experience">
    <div class="skill-category">
      <p class="form-title">
        {{ $t('views.add_new_address_job.address_job_step_3.experience.title') }}
      </p>
      <p>
        {{ $t('views.add_new_address_job.address_job_step_3.experience.subtitle') }}
      </p>
    </div>
    <div class="form-check-group">
      <div v-for="experience in experiences" :key="experience.id" class="form-check custom-check">
        <input
          :id="`experience-${experience.id}`"
          v-model="selectedExperiences"
          class="form-check-input new-experience-radio"
          type="checkbox"
          :value="experience">
        <label class="form-check-label custom-label" :for="`experience-${experience.id}`">{{ experience.name }}</label>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';
import { AddAddressJob } from '@/js/interfaces/address-job';

import ActionType from '../interfaces/action-type';
import Experience from '../interfaces/experience';

export default defineComponent({
  name: 'CandidateProfileExperience',
  props: {
    experiences: {
      type: Object as PropType<Experience[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedExperiences: [] as Experience[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {
      selectedExperiences: { required, minLength: minLength(1) },
    };
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedExperiences(): Promise<void> {
      await this.localOnSelectExperiences();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectExperiences(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        experiences: this.selectedExperiences,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedExperiences = this.localAddressJob.experiences;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  @include mq($from: extra-wide) {
    margin-top: 64px;
  }
}
</style>
