<template>
  <div class="app-layout">
    <div class="container layout-container d-flex flex-column">
      <img class="wave" src="@/images/wave.svg" alt="decide.work">
      <v-app-header @open-modal="confirmLogoutModalOpened = true" />
      <div class="app-layout-content">
        <v-app-navigation @open-modal="confirmLogoutModalOpened = true" />
        <slot />
      </div>
      <v-modal :id="'confirmLogoutModal'" v-model="confirmLogoutModalOpened">
        <h5 class="text-center mb-4">{{ $t('modals.confirm_logout.title') }}</h5>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-rounded btn-light m-2 cancel-logout-btn" @click.prevent="confirmLogoutModalOpened = false">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-rounded btn-primary btn-logout m-2 logout-btn" @click.prevent="localLogout()">
            {{ $t('actions.logout') }}
          </button>
        </div>
      </v-modal>
      <v-app-footer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapMutations } from 'vuex';

import routesNames from '../router/route-names';

export default defineComponent({
  name: 'AppLayout',
  data() {
    return {
      confirmLogoutModalOpened: false,
    };
  },
  watch: {
    $route() {
      this.resetAddressJobStore();
    },
  },
  mounted() {},
  methods: {
    ...mapActions('recruiter', [
      'logout',
    ]),
    ...mapActions('job', [
      'reset',
    ]),
    ...mapMutations('job', [
      'resetAddressJob',
    ]),
    closeModal(): void {
      this.confirmLogoutModalOpened = false;
    },
    async localLogout() {
      this.closeModal();
      await this.reset();
      await this.logout();
      this.resetAddressJob();
      window.location.href = '/';
    },
    resetAddressJobStore(): void {
      const addressJobRoutes: string[] = [
        routesNames.addAddressJobStep1,
        routesNames.addAddressJobStep2,
        routesNames.addAddressJobStep3,
        routesNames.editAddressJobStep1,
        routesNames.editAddressJobStep2,
        routesNames.editAddressJobStep3,
      ];

      if (!addressJobRoutes.includes(this.$route.name as string)) {
        this.resetAddressJob();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.wave {
  display: none;

  @include mq($from: desktop) {
    bottom: calc(100vh - 711px);
    display: inline-block;
    left: calc(-50vw + 50%);
    position: absolute;
    width: 100vw;
    z-index: auto;
  }
}

.app-layout {
  background: linear-gradient(180deg, $primary 500px, $light 500px);
  color: $white;

  @include mq($from: desktop) {
    background: // url('../../images/wave.svg') center top 710px no-repeat,
      linear-gradient($primary calc(100vh - var(--blue-height)), $light calc(100vh - var(--blue-height)));
    background-size: 101vw;
  }

  .container.layout-container {
    max-width: 1400px;
    min-height: 100vh;
  }
}

.app-layout-content {
  display: grid;
  gap: 25px;
  grid-template-columns: minmax(0, 1fr);
  padding-bottom: 90px;
  z-index: 2;

  @include mq($from: desktop) {
    grid-template-columns: max-content minmax(0, 1fr);
  }

  @media print {
    grid-template-columns: minmax(0, 1fr);
  }

  .modal-open & {
    z-index: auto;
  }
}

.btn-logout {
  white-space: nowrap;
}
</style>
