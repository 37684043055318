<template>
  <div v-if="displayLicences">
    <div v-if="displayCaces">
      <v-candidate-profile-caces @trigger-close="isCacesOpened = !isCacesOpened" />
    </div>
    <div v-else>
      <form
        class="licence-mobility">
        <div class="skill-category">
          <p class="form-title">
            {{ $t('views.add_new_address_job.address_job_step_3.licences.title2') }}
          </p>
          <p>
            {{ $t('views.add_new_address_job.address_job_step_3.licences.subtitle') }}
          </p>
          <p class="form-subtitle">
            {{ $t('views.add_new_address_job.address_job_step_3.licences.title_licences') }}
          </p>
        </div>
        <div class="form-check-group">
          <div v-for="licence in licences" :key="licence.id" class="form-check custom-check">
            <input
              :id="`licence-${licence.id}`"
              v-model="selectedLicences"
              class="form-check-input new-licence-radio"
              type="checkbox"
              :value="licence">
            <label class="form-check-label custom-label" :for="`licence-${licence.id}`">
              <img :src="licence.icon" alt="licence-icon">
              <span class="licence-name">{{ licence.name }}</span>
            </label>
          </div>
          <div class="form-check custom-check">
            <button
              type="button"
              :class="{ 'is-valid-field': localAddressJob.caces.length > 0 }"
              class="btn-caces form-check-label" @click="isCacesOpened = !isCacesOpened">
              <div class="licence-caces">
                <v-sprite color="var(--bs-body-color)" icon="caces" />
                <span class="licence-name">caces</span>
              </div>
              <v-sprite color="var(--bs-body-color)" icon="right-arrow" />
            </button>
          </div>
        </div>
      </form>
      <form
        class="licence-mobility">
        <div class="skill-category">
          <p class="form-subtitle">
            {{ $t('views.add_new_address_job.address_job_step_3.licences.title_mobility') }}
          </p>
        </div>
        <div class="form-check-group">
          <div v-for="locomotion in locomotions" :key="locomotion.id" class="form-check custom-check">
            <input
              :id="`locomotion-${locomotion.id}`"
              v-model="selectedLicences"
              class="form-check-input new-licence-radio"
              type="checkbox"
              :value="locomotion">
            <label class="form-check-label custom-label" :for="`locomotion-${locomotion.id}`">
              <img :src="locomotion.icon" alt="locomotion-icon">
              <span class="locomotion-name">{{ locomotion.name }}</span>
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">

import useVuelidate from '@vuelidate/core';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddAddressJob } from '../interfaces/address-job';
import Licence from '../interfaces/licence';
import Locomotion from '../interfaces/locomotion';

export default defineComponent({
  name: 'CandidateProfileLicences',
  props: {
    licences: {
      type: Object as PropType<Licence[]>,
      required: true,
    },
    locomotions: {
      type: Object as PropType<Locomotion[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      isCacesOpened: false,
      selectedLicences: [] as Licence[],
      selectedLocomotions: [] as Locomotion[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
    displayLicences(): boolean {
      return this.action.name === this.actionTypesType.licences
      && this.action.active;
    },
    displayCaces(): boolean {
      return this.displayLicences
      && this.isCacesOpened;
    },
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedLicences(): Promise<void> {
      await this.localOnSelectLicences();
    },
    async selectedLocomotions(): Promise<void> {
      await this.localOnSelectLocomotions();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectLicences(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        licences: this.selectedLicences,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    async localOnSelectLocomotions(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        locomotions: this.selectedLocomotions,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedLicences = this.localAddressJob.licences;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  margin-top: 1rem;

  .form-check-label {
    font-size: 16px;
    gap: 10px;
    min-height: 70px;
    text-align: left;
  }

  .form-check:last-of-type {
    grid-column: 1/-1;
  }

  .btn-caces {
    background-color: transparent;
    justify-content: space-between;
    text-transform: uppercase;
  }

  .licence-caces {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  .is-valid-field {
    background-color: $purple-200;
    border-color: $primary;
    color: $primary;
  }
}

.form-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-block: 2rem 1rem;
}
</style>
