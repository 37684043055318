<template>
  <div>
    <form
      v-if="action.name === actionTypesType.professionalKnowledge && action.active"
      class="professional-soft-skill" @submit.prevent="saveTheStep(); $emit('selectAction')">
      <div class="form-header">
        <p class="title">
          {{ $t('views.register_step_3.professional_knowledge.subtitle_1') }}
          <span class="subtitle">{{ $t('views.register_step_3.professional_knowledge.subtitle_1_span') }}</span>
        </p>
      </div>

      <div v-if="professionalSoftSkills" class="form-check-group">
        <div v-for="skill in professionalSoftSkills" :key="skill.id" class="form-check">
          <label class="form-check-label custom-label" :for="`skill-${skill.id}`">
            <span class="name fw-bold">{{ skill.name }}: </span>
            <span class="description">{{ skill.description }}</span>
          </label>
          <input
            :id="`skill-${skill.id}`"
            v-model="selectedProfessionalSoftSkills"
            class="form-check-input new-skill-radio"
            type="checkbox"
            :value="skill">
        </div>
      </div>
      <div
        v-if="v.selectedProfessionalSoftSkills.maxLength.$invalid"
        class="invalid-message sticky-message"
      >
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('views.register_step_3.professional_knowledge.invalid_selections_soft_skill') }} {{ selectedProfessionalSoftSkills.length }}
      </div>
      <br>
      <div
        v-if="v.selectedProfessionalSoftSkills.$invalid && !v.selectedProfessionalSoftSkills.maxLength.$invalid"
        class="invalid-message"
      >
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('form.validation.required') }}
      </div>
      <div class="register-submit">
        <button
          type="submit" class="btn btn-primary btn-rounded"
          :disabled="v.selectedProfessionalSoftSkills.$invalid || v.selectedProfessionalKnowledge.$invalid"
        >
          {{ $t('actions.save') }}
        </button>
      </div>
    </form>
    <form
      v-if="action.name === actionTypesType.professionalKnowledge && action.active"
      class="professional-knowledge" @submit.prevent="saveTheStep(); $emit('selectAction')">
      <div class="form-header">
        <p class="title">
          {{ $t('views.register_step_3.professional_knowledge.subtitle_2') }}
          <span class="subtitle">{{ $t('views.register_step_3.professional_knowledge.subtitle_2_span') }}</span>
        </p>
      </div>

      <div v-if="professionalKnowledge" class="form-check-group">
        <div v-for="skill in professionalKnowledge" :key="skill.id" class="form-check">
          <label class="form-check-label custom-label" :for="`skill-${skill.id}`">
            <span class="name fw-bold">{{ skill.name }}: </span>
            <span class="description">{{ skill.description }}</span>
          </label>
          <input
            :id="`skill-${skill.id}`"
            v-model="selectedProfessionalKnowledge"
            class="form-check-input new-skill-radio"
            type="checkbox"
            :value="skill">
        </div>
      </div>
      <div
        v-if="v.selectedProfessionalKnowledge.maxLength.$invalid"
        class="invalid-message sticky-message"
      >
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('views.register_step_3.professional_knowledge.invalid_selections_knowledge') }} {{ selectedProfessionalKnowledge.length }}
      </div>
      <br>
      <div
        v-if="v.selectedProfessionalKnowledge.$invalid && !v.selectedProfessionalKnowledge.maxLength.$invalid"
        class="invalid-message"
      >
        <v-sprite color="var(--bs-pink)" icon="info" />
        {{ $t('form.validation.required') }}
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';

import { ActionTypesType } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddressJob } from '../interfaces/address-job';
import { Skill } from '../interfaces/skill';

export default defineComponent({
  name: 'RegisterProfessionalKnowledge',
  props: {
    professionalKnowledge: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    professionalSoftSkills: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  emits: ['selectAction'],
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedProfessionalKnowledge: [] as Skill[],
      selectedProfessionalSoftSkills: [] as Skill[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddressJob,
      actionTypesType: ActionTypesType,
    };
  },
  validations() {
    return {
      selectedProfessionalKnowledge: { required, minLength: minLength(1), maxLength: maxLength(3) },
      selectedProfessionalSoftSkills: { required, minLength: minLength(1), maxLength: maxLength(3) },
    };
  },
  computed: {
    ...mapState('register', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddressJob) {
      this.localAddressJob = newJob;
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = this.addressJob;
  },
  methods: {
    ...mapActions('register', [
      'setAddressJob',
    ]),
    async localOnSelectProfessionalKnowledge(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        professionalKnowledge: this.selectedProfessionalKnowledge,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    async localOnSelectProfessionalSoftSkills(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        professionalSoftSkills: this.selectedProfessionalSoftSkills,
      };
      await this.setAddressJob(this.localAddressJob);
    },

    async saveTheStep(): Promise<void> {
      await this.localOnSelectProfessionalKnowledge();
      await this.localOnSelectProfessionalSoftSkills();
    },
  },
});
</script>
<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.professional-knowledge {
  margin-top: 32px;

  @include mq($from: tablet) {
    margin-top: 62px;
  }
}

.professional-soft-skill {
  margin-top: 32px;
}

.step3-forms {
  .form-check-group {
    gap: 8px;
  }

  .form-header {
    margin-block: 0 16px;

    .title {
      font-size: 20px;
    }

    .subtitle {
      color: $pink-500;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.form-check {
  align-items: center;
  display: flex;
  gap: 56px;
  justify-content: space-between;
  margin: 0;
  min-height: unset;
  padding: 0;

  + .form-check {
    border-top: 1px solid $steel;
    padding-top: 8px;
  }

  .form-check-input {
    float: unset;
    margin: 0;
  }

  .form-check-label {
    font-size: 16px;
    height: auto;
    text-align: left;
  }
}

.sticky-message {
  bottom: 6rem;

  @include mq($from: desktop) {
    bottom: 12rem;
  }
}
</style>
