<template>
  <form
    v-if="action.name === actionTypesType.experience && action.active"
    class="experience" @submit.prevent="saveTheStep(); $emit('selectAction')">
    <div class="form-header">
      <p class="title">
        {{ $t('views.register_step_3.experience.title') }}
      </p>
      <p class="subtitle">
        {{ $t('views.register_step_3.experience.subtitle') }}
      </p>
    </div>

    <div class="form-check-group">
      <div v-for="experience in experiences" :key="experience.id" class="form-check custom-check">
        <input
          :id="`experience-${experience.id}`"
          v-model="selectedExperiences"
          class="form-check-input"
          type="checkbox"
          :value="experience">
        <label class="form-check-label custom-label" :for="`experience-${experience.id}`">{{ experience.name }}</label>
      </div>
    </div>
    <div
      v-if="v.selectedExperiences.$invalid"
      class="invalid-message"
    >
      <v-sprite color="var(--bs-pink)" icon="info" />
      {{ $t('form.validation.required') }}
    </div>
    <div class="register-submit">
      <button
        type="submit" class="btn btn-primary btn-rounded"
        :disabled="v.selectedExperiences.$invalid"
      >
        {{ $t('actions.save') }}
      </button>
    </div>
  </form>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';

import { ActionTypesType } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddressJob } from '../interfaces/address-job';
import Experience from '../interfaces/experience';

export default defineComponent({
  name: 'RegisterExperience',
  props: {
    experiences: {
      type: Object as PropType<Experience[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  emits: ['selectAction'],
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedExperiences: [] as Experience[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddressJob,
      actionTypesType: ActionTypesType,
    };
  },
  validations() {
    return {
      selectedExperiences: { required, minLength: minLength(1) },
    };
  },
  computed: {
    ...mapState('register', [
      'currentStep',
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddressJob) {
      this.localAddressJob = newJob;
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = this.addressJob;
  },
  methods: {
    ...mapActions('register', [
      'setAddressJob',
    ]),
    async localOnSelectExperiences(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        experiences: this.selectedExperiences,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    async saveTheStep(): Promise<void> {
      await this.localOnSelectExperiences();
    },
  },
});
</script>
  <style lang="scss" scoped>

  </style>
