<template>
  <div class="job-filter">
    <div v-if="jobFilters.length > 0" class="resumes-filter">
      <div class="resume-filter jobs dropdown">
        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleJobFilters">
          {{ selectedJob ? selectedJob.name : $t('components.resumes_job_filter.all_jobs_choices') }}
          <v-sprite color="#fff" icon="dropdown_arrow" />
        </button>

        <ul class="dropdown-menu" :class="{ show: showJobFilters }">
          <li>
            <a
              href="#"
              class="dropdown-item"
              :class="{ active: selectedJob === null }"
              @click.prevent="selectJob(null)">
              <v-checkbox-indicator :checked="selectedJob === null" />
              {{ $t('components.resumes_filters.all') }}
            </a>
          </li>
          <li
            v-for="job in jobFilters"
            :key="job.id">
            <a
              href="#"
              class="dropdown-item"
              :class="{ active: selectedJob && selectedJob.id === job.id }"
              @click.prevent="selectJob(job)">
              <v-checkbox-indicator :checked="selectedJob && selectedJob.id === job.id" />
              {{ job.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapState } from 'vuex';

import { AddressJob } from '@/js/interfaces/address-job';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'ResumesJobFilter',
  props: {
    selectedJob: {
      type: Object as PropType<AddressJob | null>,
      default: null,
    },
  },
  emits: ['update:selectedJob'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      jobFilters: [] as AddressJob[],
      showJobFilters: false,
      showExperienceFilters: false,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'jobs',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
  },
  watch: {},
  mounted(): void {
    this.jobFilters = this.jobs;

    // If the job uuid is in the query params, select the job
    const jobUuid = this.$route.query.job;
    if (jobUuid) {
      const job = this.jobFilters.find((addressJob: AddressJob) => addressJob.uuid === jobUuid);

      if (job) {
        this.toggleJobFilters();
        this.selectJob(job);
      }
    }
  },
  methods: {
    selectJob(job: AddressJob|null): void {
      this.$emit('update:selectedJob', job);
      this.toggleJobFilters();

      // Add or replace job uuid in the query params
      if (job) {
        this.$router.push({ name: this.routeNames.resumesShop, query: { job: job.uuid } });
      } else {
        this.$router.push({ name: this.routeNames.resumesShop });
      }
    },

    toggleJobFilters(): void {
      this.showJobFilters = !this.showJobFilters;
      this.showExperienceFilters = false;
    },

    toggleExperienceFilters(): void {
      this.showExperienceFilters = !this.showExperienceFilters;
      this.showJobFilters = false;
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-filter {
  display: flex;
  justify-content: end;
  margin: 32px 0;
}

.resumes-header-filter {
  .dropdown-toggle {
    background: none;
    border: 0;
    color: $white;
    padding: 0 18px;

    &::after {
      content: none;
    }

    svg {
      margin-left: 18px;
      transition: transform .3s;
    }
  }

  .dropdown-item {
    display: inline-flex;
    font-size: 14px;
    gap: 5px;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
  }
}

.resume-filter {
  &:has(.dropdown-menu.show) {
    .dropdown-toggle svg {
      transform: rotate(180deg);
    }
  }
}
</style>
