<template>
  <div class="store-switch">
    <div v-if="currentAddress" class="d-flex">
      <router-link
        class="store-switch-container"
        :to="{ name: routeNames.resumesShop }">
        <button
          type="button"
          class="store-switch-wrapper"
          :class="{ 'store-switch-wrapper-essential': !isPremium }"
          @click.prevent="modalOpened = isOnSwitchablePage"
          @keyup.enter.prevent="modalOpened = isOnSwitchablePage">
          <div class="store-switch-avatar">
            <div class="logo-wrapper">
              <img v-if="currentAddress.logo" :src="currentAddress.logo" alt="" class="img-fluid" width="80" height="80">
              <div v-else class="text-xxl address-first-letter fw-bold">{{ nameFirstLetter(currentAddress.name) }}</div>
            </div>

            <v-sprite v-if="isOnSwitchablePage && isPremium" class="arrow-icon" icon="caret" />
          </div>
          <div class="store-switch-content">
            <div class="store-switch-title">{{ currentAddress.name }}</div>
            <div v-if="currentAddress?.childrenLength === 0" class="store-switch-subtitle">
              {{ currentAddress.content.featureName }} <br>
              {{ currentAddress.content.description }}
            </div>
            <div v-if="currentAddress?.premium && currentAddress?.childrenLength > 0" class="store-switch-subtitle">
              {{ $t('views.addresses.parent') }}
            </div>
            <div v-if="subscriptionIsActive === false && !currentAddress.premium && !isHeadHunter">
              <strong>{{ $t('components.store_switch.subscription_is_over') }}</strong>
            </div>
          </div>
        </button>
      </router-link>
      <div class="d-flex">
        <div v-if="!isHeadHunter" class="store-switch-print-links-wrapper">
          <div>{{ $t('components.store_switch.attract_candidates') }}</div>

          <div class="store-switch-print-links">
            <div class="store-switch-print-link">
              <button
                id="store-switch-print-link-print-qr-code"
                type="button"
                class="btn btn-keep-size btn-rounded"
                data-bs-toggle="tooltip"
                :data-bs-title="$t('components.store_switch.tooltip.qr_code_download')"
                data-bs-placement="bottom"
                :title="$t('components.store_switch.tooltip.qr_code_download')"
                @click.prevent="printQrCode(false)">
                <v-sprite color="currentColor" icon="print" class="print-icon" />
              </button>
            </div>

            <div class="visually-hidden">
              <button
                id="store-switch-print-link-print-qr-code-small"
                type="button"
                @click.prevent="printQrCode(true)" />
            </div>

            <div class="store-switch-print-link">
              <button
                id="store-switch-print-link-download-qr-code-small"
                type="button"
                class="btn btn-keep-size btn-rounded qr-code-icon-wrapper"
                data-bs-toggle="tooltip"
                :data-bs-title="$t('components.store_switch.tooltip.qr_code_download_small')"
                data-bs-placement="bottom"
                :title="$t('components.store_switch.tooltip.qr_code_download_small')"
                @click.prevent="downloadQrCode(true)">
                <v-sprite color="currentColor" icon="download" class="download-icon" />
              </button>
            </div>

            <div class="visually-hidden">
              <button
                id="store-switch-print-link-download-qr-code"
                type="button"
                @click.prevent="downloadQrCode(false)" />
            </div>

            <div class="store-switch-print-link">
              <button
                id="store-switch-print-link-shop"
                type="button"
                class="btn btn-keep-size btn-rounded qr-code-icon-wrapper"
                data-bs-toggle="tooltip"
                :data-bs-title="$t('components.store_switch.tooltip.cart')"
                data-bs-placement="bottom"
                :title="$t('components.store_switch.tooltip.cart')"
                @click.prevent="qrCodeModalOpened = true">
                <v-sprite color="currentColor" icon="shop" class="shop-icon" />
              </button>
            </div>
          </div>
        </div>
        <div v-else class="d-none d-md-flex justify-content-center align-items-center">
          <v-app-header-basket />
          <router-link
            class="d-none d-lg-block button-download-qr-code btn btn-keep-size btn-rounded"
            :to="{ name: routeNames.myQrCodes }">
            {{ $t('components.app_navigation.my_qr_codes') }}
          </router-link>
        </div>

        <v-modal v-if="user" id="qrCodeModal" v-model="qrCodeModalOpened">
          <v-order-sticker
            v-if="currentAddress && (
              (currentAddress.subscription && currentAddress.subscription.status === 'active') || currentAddress?.premium
            )"
            :address="currentAddress"
            :price="price"
            :subscription="currentAddress.subscription" />
        </v-modal>

        <v-modal v-if="user" :id="modalId" v-model="modalOpened" size="modal-lg">
          <div class="store-switch-list">
            <div v-if="parentAddress" class="store-switch-address-content-wrapper">
              <v-store-switch-address
                :address="parentAddress"
                class="store-switch-parent-address"
                @on-choose-address="chooseAddress($event)" />
            </div>

            <div class="store-switch-search">
              <label for="address-filter" class="sr-only">{{ $t('components.store_switch.filter') }}</label>
              <v-search-bar
                v-model="query"
                :placeholder="$t('components.store_switch.filter')"
                @on-query-updated="onQueryUpdated()" />
            </div>

            <div class="store-switch-address-content-wrapper">
              <div v-for="address in activeAddresses" :key="address.uuid" class="store-switch-address-content">
                <v-store-switch-address :address="address" @on-choose-address="chooseAddress($event)" />
              </div>
            </div>

            <div v-if="nextPageNumber || previousPageNumber" class="pagination d-flex justify-content-center align-items-center">
              <vue-paginate
                :page-count="totalPages"
                :click-handler="setPage"
                :prev-text="'Précédent'"
                :next-text="'Suivant'"
                :container-class="'pagination-container d-flex align-items-center'"
                :page-class="'pagination-item'"
                :page-link-class="'pagination-link'"
                :prev-class="'pagination-navigation text-white bg-primary'"
                :next-class="'pagination-navigation text-white bg-primary'"
                :hide-prev-next="true"
              />
            </div>

            <div class="mt-5 text-center">
              <button type="button" class="btn btn-primary btn-icon btn-rounded" @click.prevent="goToAddresses">
                <v-sprite icon="gear" />
                {{ $t('components.store_switch.manage_addresses') }}
              </button>
            </div>
          </div>
        </v-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { VuePaginate } from '@svifty7/vue-paginate';
import { Tooltip } from 'bootstrap';
import saveAs from 'file-saver';
import { cloneDeep, DebouncedFunc, throttle } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapGetters, mapState } from 'vuex';

import Address from '@/js/interfaces/address';

import { useQrCode } from '../composition/qr-code';
import Price from '../interfaces/price';
import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'StoreSwitch',
  components: { VuePaginate },
  setup() {
    const toast = useToast();
    const {
      printQrCode, getQrCodeBlob, qrCodeUuuid, downloadQrCode,
    } = useQrCode();
    return {
      toast, printQrCode, getQrCodeBlob, qrCodeUuuid, downloadQrCode,
    };
  },
  data() {
    return {
      localAddresses: [] as Address[],
      modalOpened: false,
      qrCodeModalOpened: false,
      modalId: 'storeSwitchModal',
      query: '',
      page: 1 as number,
      count: 0 as number,
      totalPages: 0 as number,
      pageSize: 0 as number,
      previousPageNumber: null as (number | null),
      nextPageNumber: null as (number | null),
      lookupAddressHandler: throttle(this.localGetAddresses, 1000) as DebouncedFunc<()=> void>,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
      'user',
      'parentAddress',
      'addresses',
    ]),

    ...mapGetters('recruiter', [
      'isPremium',
      'subscriptionIsActive',
      'isPremium',
      'isHeadHunter',
    ]),
    ...mapGetters('register', [
      'subscriptions',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    price(): Price | null {
      let addressPrice = null;

      if (this.subscriptions && this.currentAddress && this.currentAddress.subscription) {
        for (const subscription of this.subscriptions) {
          const a = subscription.prices.find((price: Price) => price.id === this.currentAddress?.subscription?.plan.id);
          if (a) {
            addressPrice = a;
          }
        }
      }

      return addressPrice;
    },

    activeAddresses(): Address[] {
      return this
        .localAddresses
        .filter((address) => address.subscription || address.premium)
        .filter((address) => address.childrenLength === 0);
    },
    isOnResumesPage() {
      return this.$route.name === routesNames.resumes;
    },

    isOnSwitchablePage(): boolean {
      const switchables = [routesNames.account, routesNames.addresses, routesNames.myQrCodes];
      return !!(this.$route.name && !switchables.includes(this.$route.name as string));
    },

    isOnCommonPages(): boolean {
      return this.$route.name === routesNames.addresses
      || this.$route.name === routesNames.myQrCodes
      || this.$route.name === routesNames.account;
    },

  },
  async mounted(): Promise<void> {
    if (this.isPremium) {
      await this.goToParentAddress();
    }

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map((tooltipTriggerElement) => new Tooltip(tooltipTriggerElement));
    this.localAddresses = cloneDeep(this.addresses);
  },
  methods: {
    ...mapActions('recruiter', [
      'setCurrentAddress',
      'getAddresses',
      'goToParentAddress',
    ]),
    chooseAddress(address: Address): void {
      this.query = '';
      this.setCurrentAddress(address);
      this.modalOpened = false;

      const managerRoutes = [routesNames.account, routesNames.myQrCodes, routesNames.addresses];
      const shouldRedirect = this.$route.name !== routesNames.dashboard && managerRoutes.includes(this.$route.name as string);

      if (shouldRedirect) {
        this.$router.push({ name: routesNames.dashboard });
      }
    },
    nameFirstLetter(addressName: string): string {
      return addressName[0].toUpperCase();
    },
    async setPage(page: number) {
      this.page = page;
      this.lookupAddressHandler();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    async onQueryUpdated(): Promise<void> {
      if (this.query) {
        this.page = 1;
      }

      this.lookupAddressHandler();
    },
    async localGetAddresses(): Promise<void> {
      const {
        count,
        totalPages,
        pageSize,
        previousPageNumber,
        nextPageNumber,
        results,
      } = await this.getAddresses({ page: this.page, query: this.query });

      this.count = count;
      this.totalPages = totalPages;
      this.pageSize = pageSize;
      this.previousPageNumber = previousPageNumber;
      this.nextPageNumber = nextPageNumber;
      this.localAddresses = results as Array<Address>;
    },
    goToAddresses(): void {
      this.modalOpened = false;

      if (this.$route.name !== routesNames.addresses) {
        this.$router.replace({ name: routesNames.addresses });
      }
    },

    goToResumes(): void {
      this.modalOpened = false;

      if (this.$route.name !== routesNames.resumes) {
        this.$router.push({ name: routesNames.resumes });
      }
    },

  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

// Mobile version of the "attract candidates" menu
@include offcanvas();

.printer-icon {
  fill: currentColor;
}

.qr-code-icon {
  margin: 3px 0 0 3px;
}

.store-switch-content {
  cursor: pointer;
}

.store-switch-wrapper {
  align-items: center;
  background: none;
  border: 0;
  color: $white;
  display: grid;
  flex: 1;
  gap: $spacer;
  padding: 0;
  text-align: left;

  @include mq($from: tablet) {
    grid-template-columns: 80px 1fr;
  }

  @include mq($from: desktop) {
    border-right: 1px solid #6861c2;
    margin-right: 50px;
    padding-right: $spacer;
  }

  &.store-switch-wrapper-essential {
    pointer-events: none;
  }
}

.address-first-letter {
  color: $blue;
  font-family: $headings-font-family;
}

.store-switch-avatar,
.addresses-page-avatar {
  @include store-avatar;
  border: 2px solid $secondary;
  color: $body-color;
  position: relative;

  .logo-wrapper {
    @include store-avatar;
    border: 2px solid $secondary;
    overflow: hidden;
  }

  .arrow-icon {
    bottom: -2px;
    position: absolute;
    right: -2px;
  }
}

.store-switch-title {
  font-family: $headings-font-family;
  font-size: 20px;
  font-weight: 700;
}

.store-switch-subtitle {
  font-size: 14px;

  @include mq($from: tablet) {
    font-size: 16px;
  }
}

// Modal
.store-switch-list {
  @include mq($from: tablet) {
    margin: 0;
    padding: 0 $spacer $spacer;
  }
}

.store-switch-search {
  margin: 30px 0 10px;
  max-width: 330px;
}

.store-switch-address-content-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

.store-switch-address-content {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-400;
  }
}

.store-switch-parent-address {
  background-color: rgb(246, 249, 252);
  border-radius: 10px;
}

// Print icon
.store-switch-print-links-wrapper {
  display: none;

  @include mq($from: desktop) {
    align-items: center;
    display: flex;
    gap: $spacer;
    justify-content: space-between;
  }
}

.store-switch-print-links-mobile-wrapper {
  align-self: center;
  display: block;

  @include mq($from: desktop) {
    display: none;
  }
}

.store-switch-print-links {
  display: none;

  @include mq($from: desktop) {
    display: flex;
    gap: $spacer;
    justify-content: center;
  }
}

.store-switch-print-link {
  font-size: 14px;
  line-height: $headings-line-height;
  max-width: 120px;
  text-align: center;

  .btn {
    @include button-variant(
      #6861c2, #6861c2, $white,
      $white, $white, $primary,
      $white, $white, $primary
    );
    align-items: center;
    aspect-ratio: 1;
    box-shadow: none;
    display: inline-flex;
    height: 42px;
    justify-content: center;
    margin-bottom: $spacer * .5;
    padding: 0;
    width: 42px;
  }
}

.btn-mobile-store-switch {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 32px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px;
}

.store-switch-offcanvas-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: $spacer * 2;
}

.store-switch-offcanvas-link {
  color: $white;
  display: flex;
  gap: $spacer;
  margin-bottom: $spacer * 2;
  text-decoration: none;
}

.address-filter-form-control {
  border: 0;
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  margin: 30px 0 20px;
  min-width: 330px;
  padding: 13px 57px 13px 20px;
}

.svg-magnifier {
  position: absolute;
  right: 20px;
  top: 39px;
}

.button-download-qr-code {
  border: 1px solid #fff;
  color: #fff !important;
  line-height: 1;
  white-space: nowrap;
}

.store-switch-container {
  display: block;
  text-decoration: none;
  width: 100%;
}
</style>
