<template>
  <div class="caces-modal">
    <button class="return" type="button" @click="$emit('trigger-close')">
      <v-sprite color="#717296" icon="arrow-back" />
    </button>
    <form
      class="caces-mobility">
      <div class="skill-category">
        <p class="form-title">
          {{ $t('views.add_new_address_job.address_job_step_3.cacess.title') }}
        </p>
      </div>
      <div class="caces-grid">
        <div v-for="c in caces" :key="c.id" class="form-check custom-check">
          <input
            :id="`caces-${c.id}`"
            v-model="selectedCaces"
            class="form-check-input new-caces-radio"
            type="checkbox"
            :value="c">
          <label class="form-check-label custom-label" :for="`caces-${c.id}`">
            <span class="caces-name">{{ c.name }}</span>
          </label>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">

import useVuelidate from '@vuelidate/core';
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import { useJobSkill } from '../composition/job-skills';
import { AddAddressJob } from '../interfaces/address-job';
import Caces from '../interfaces/caces';

export default defineComponent({
  name: 'CandidateProfileCaces',
  props: {},
  emits: ['trigger-close'],
  setup() {
    const v = useVuelidate();
    const {
      caces,
      getCaces,
    } = useJobSkill();
    return {
      v,
      caces,
      getCaces,
    };
  },
  data() {
    return {
      selectedCaces: [] as Caces[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    selectedCaces(): void {
      this.localOnSelectCaces();
    },
  },
  async mounted(): Promise<void> {
    await this.getCaces();
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    localOnSelectCaces(): void {
      this.localAddressJob = {
        ...this.localAddressJob,
        caces: this.selectedCaces,
      };
      this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedCaces = this.localAddressJob.caces;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.return {
  background-color: transparent;
  border: 0;
}

.caces-mobility {
  margin-top: 32px;
}

.caces-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .form-check-label {
    justify-content: center;
    width: 100%;
  }
}

.caces-modal {
  @include mq($until: tablet) {
    background-color: $light;
    left: 0;
    min-height: 100vh;
    padding: 32px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
