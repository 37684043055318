<template>
  <div class="job-card card">
    <div class="card-body">
      <div class="job-card-content">
        <div class="job-card-top">
          <span class="town">{{ getJobTown }}</span>
          <span class="active-badge" :class="{ active: localJob.active }" />
        </div>
        <div class="job-card-name">{{ localJob.name }}</div>

        <div class="selected-values">
          <div v-for="tag in addressJobTags" :key="tag" class="value-badge">
            {{ tag }}
          </div>
        </div>

        <div class="selected-values">
          <div v-for="tag in addressJobRemoteTags" :key="tag" class="value-badge">
            {{ tag }}
          </div>
        </div>

        <div class="job-card-footer">
          <div v-if="localJob.urgent" class="urgent">
            {{ $t('components.job_card.urgent') }}
          </div>
          <button type="button" class="btn btn-actions" @click="isJobActions = !isJobActions">
            <span /><span /><span />
          </button>
        </div>
      </div>
    </div>
    <div v-if="isJobActions" class="job-card-actions">
      <router-link
        v-if="user?.recruiter.headhunter"
        class="btn-icon"
        :to="{ name: routeNames.resumesShop, query: { job: localJob.uuid } }">
        <v-sprite icon="goto" />
        <span>{{ $t('actions.access') }}</span>
      </router-link>

      <router-link v-if="user?.recruiter.premium" class="btn-icon" :to="{ name: routeNames.resumes, query: { job: localJob.uuid } }">
        <v-sprite icon="goto" />
        <span>{{ $t('actions.access') }}</span>
      </router-link>

      <router-link class="btn-icon" :to="{ name: routeNames.editAddressJobStep1, params: { uuid: localJob.uuid } }">
        <v-sprite icon="edit" />
        <span>{{ $t('actions.update') }}</span>
      </router-link>

      <button type="button" class="btn btn-link btn-icon p-0 delete" @keydown.enter.prevent="removeJob" @click.prevent="removeJob">
        <v-sprite color="currentColor" icon="trash" />
        <span>{{ $t('actions.delete') }}</span>
      </button>
    </div>
    <v-modal :id="`standByModalId${localJob.id}`" v-model="standByModalOpened" @update:modal-opened="cancelStandBy">
      <h5 class="stand-by-title text-center">Désactiver un métier</h5>
      <p class="stand-by-content text-center">
        Attention en désactivant ce métier, votre implantation ne sera plus visible sur votre QR Code.
      </p>

      <div class="stand-by-cta">
        <button type="button" class="btn btn-rounded btn-outline-primary" @click.prevent="cancelStandBy">
          Annuler
        </button>
        <button type="button" class="btn btn-rounded btn-primary" @click.prevent="standBy()">Mettre en veille</button>
      </div>
    </v-modal>

    <v-modal :id="`editModalId${localJob.id}`" v-model="editModalOpened" size="modal-lg">
      <v-edit-job-form
        v-model="localJob"
        @cancel="editModalOpened = false"
        @job-updated="onJobUpdated($event)" />
    </v-modal>
  </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { AddressJob } from '../interfaces/address-job';
import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'JobCard',
  props: {
    job: {
      type: Object as PropType<AddressJob>,
      required: true,
    },
  },
  emits: ['job-deleted'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localJob: cloneDeep(this.job) as AddressJob,
      standByModalOpened: false,
      editModalOpened: false,
      isJobActions: false,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'jobs',
      'user',
      'currentAddress',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    getJobTown(): string {
      return this.localJob.town.properties.name;
    },
    addressJobTags(): string[] {
      const tags = [];
      const contractsNames = this.localJob.contracts?.map((contract) => contract.name) ?? [];
      tags.push(...contractsNames);

      if (this.localJob.workingTime) {
        tags.push(this.localJob.workingTime.name);
      }

      return tags;
    },
    addressJobRemoteTags(): string[] {
      const tags = [];

      if (this.localJob.remote) {
        tags.push(this.localJob.remote.name);
      }

      return tags;
    },
  },
  watch: {
    job(newJob: AddressJob | null): void {
      if (newJob) {
        this.onJobUpdated(newJob);
      }
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'updateJob',
      'deleteJob',
    ]),

    /**
     * When the user closes the "stand-by" modal, put the "active" model back.
     */
    cancelStandBy(): void {
      if (!Cookies.get('standByModalOpened')) {
        this.standByModalOpened = false;
        this.localJob.active = true;
      }
    },

    /**
     * This method is called when the user chooses to put on stand-by
     * the current address.
     */
    async standBy(): Promise<void> {
      await this.saveJob();

      // Set a session cookie to prevent the last inactive card
      // to be active again after the "stand-by" is set
      Cookies.set('standByModalOpened', 'true');

      this.standByModalOpened = false;

      // Remove the session cookie after one second
      setTimeout(() => {
        Cookies.remove('standByModalOpened');
      }, 1000);
    },

    /**
     * This method is called when the job is updated through the edit modal.
     */
    onJobUpdated(newJob: AddressJob | null): void {
      this.editModalOpened = false;

      if (newJob) {
        this.localJob = cloneDeep(newJob);
      }
    },

    /**
     * Do the actual save with the API, using the recruiter store.
     */
    async saveJob(): Promise<void> {
      try {
        await this.updateJob({
          uuid: this.localJob.uuid,
          data: {
            active: this.localJob.active,
            urgent: this.localJob.urgent,
          },
        });
      } catch {
        this.toast.error((this.$t('components.job_card.update_error') as string));
      }
    },

    /**
     * This method is called when the "active" switch is changed.
     * If the user is about to deactivate the last job, it should
     * display a modal telling the user this address is going
     * to be in stand by.
     */
    async updateActive(): Promise<void> {
      if (this.localJob.urgent && !this.localJob.active) {
        this.localJob.urgent = false;
      }

      const shouldDisplayStandByModal = !this.localJob.active
      && this
        .jobs
        .filter((job: AddressJob) => job.id !== this.localJob.id)
        .every((job: AddressJob) => !job.active);

      if (shouldDisplayStandByModal) {
        this.standByModalOpened = true;
      } else {
        await this.saveJob();
      }
    },
    /**
     * This method is called when the "urgent" switch is changed.
     * If the job is flagged has "urgent", but inactive, we
     * activate it automatically.
     */
    async updateUrgent(): Promise<void> {
      if (this.localJob.urgent && !this.localJob.active) {
        this.localJob.active = true;
      }

      await this.saveJob();
    },

    /**
     * This method is called when the user clicks on the thrash bin icon.
     */
    async removeJob(): Promise<void> {
      try {
      // Delete the job
        await this.deleteJob(this.localJob.uuid);

        // Trigger jobs refresh
        this.$emit('job-deleted');

        this.toast.success((this.$t('components.job_card.delete_success') as string));
      } catch {
        this.toast.error((this.$t('components.job_card.delete_error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

@include job-card();

.job-card-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: .5rem;
  height: 100%;
}

.selected-values {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.value-badge {
  align-items: center;
  background-color: #d7d5d3;
  border-radius: 8px;
  color: #1d1d1f;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 4px 6px;
}

.job-card-cta {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  .btn {
    font-size: 14px;
    padding: 0;
  }
}

// Stand by modal
.stand-by-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 44px;
}

.stand-by-content {
  margin-bottom: 44px;
}

.stand-by-cta {
  display: flex;
  gap: $grid-gutter-width;
  justify-content: center;
}

.btn-update {
  font-weight: inherit;
}

.underline-text {
  text-decoration: underline;
}

.custom-switchs {
  display: flex;
  gap: 22px;
  margin-bottom: 8px;
}

.job-card-top {
  display: flex;
  font-size: 16px;
  gap: 1rem;
  justify-content: space-between;
}

.job-card-name {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 2lh;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
}

.job-card-actions {
  background-color: #fff;
  border-radius: 16px;
  bottom: 0;
  box-shadow: $card-box-shadow;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  right: 0;
  transform: translateY(calc(100% - 10px));
  width: 150px;
  z-index: 1;

  .btn-icon {
    align-items: center;
    color: $steel;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    gap: 12px;
    grid-template-columns: 20px 1fr;
    text-align: left;
    text-decoration: none;
  }

  .delete {
    color: $pink-500;
  }
}

.active-badge {
  background-color: rgb(233, 14, 14);
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.active-badge.active {
  background-color: #008000;
}

.job-card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  justify-content: space-between;
  margin-top: auto;

  .urgent {
    color: $pink-500;
    font-weight: 700;
  }
}

.btn-actions {
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 20px;
  color: $steel;
  display: flex;
  gap: 5px;
  height: 33px;
  justify-content: center;
  margin-left: auto;
  padding: 0;
  width: 63px;

  span {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
  }
}
</style>
