<template>
  <div class="resumes-shop-list">
    <div class="resumes-header-filter">
      <v-resumes-job-filter
        v-model:selected-job="selectedJobFilter"
      />
    </div>
    <div class="candidates-content">
      <div v-if="localNewCandidatesList.length > 0" class="new-candidates-content">
        <p class="candidates-matching-title">
          {{ $t('components.resume_shop_list.new_matching_candidates_count', localNewCandidatesList.length) }}
        </p>
        <div class="candidates-grid new-candidates">
          <v-candidate-card
            v-for="candidate in localNewCandidatesList"
            :key="candidate"
            :candidate="candidate"
            @refresh-resumes="handleCandidatesList()"
          />
        </div>
      </div>
      <p v-if="localNewCandidatesList.length > 0" class="candidates-matching-title">
        {{ $t('components.resume_shop_list.matching_candidates_count_with_new', localNewCandidatesList.length) }}
      </p>
      <p v-else class="candidates-matching-title">
        {{ $t('components.resume_shop_list.matching_candidates_count', localCandidatesList.length) }}
      </p>
      <div class="candidates-grid">
        <v-candidate-card
          v-for="candidate in localCandidatesList"
          :key="candidate"
          :candidate="candidate"
          @refresh-resumes="handleCandidatesList()"
        />
      </div>
      <div v-if="count > 0" class="pagination">
        <vue-paginate
          :page-count="totalPages"
          :click-handler="setPage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination-container d-flex align-items-center'"
          :page-class="'pagination-item'"
          :page-link-class="'pagination-link'"
          :prev-class="'pagination-navigation'"
          :next-class="'pagination-navigation'"
          :hide-prev-next="true"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { VuePaginate } from '@svifty7/vue-paginate';
import { DebouncedFunc, throttle } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapMutations } from 'vuex';

import { CandidateShopItem } from '../interfaces/candidate-shop-item';
import Job from '../interfaces/job';
import PaginatedResult from '../interfaces/pagination';

interface PaginatedCandidateResponse {
  count: number;
  totalPages: number;
  pageSize: number;
  previousPageNumber: number | null;
  nextPageNumber: number | null;
  results: CandidateShopItem[];
}

export default defineComponent({
  name: 'ResumeShopList',
  components: { VuePaginate },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  data() {
    return {
      localCandidatesList: [] as CandidateShopItem[],
      localNewCandidatesList: [] as CandidateShopItem[],
      page: 1,
      count: 0,
      totalPages: 1,
      pageSize: 5,
      previousPageNumber: null as (null | number),
      nextPageNumber: null as (null | number),
      lookupAddressHandler: throttle(this.getMatchingCandidateList, 1000) as DebouncedFunc<()=> void>,

      selectedJobFilter: null as Job | null,
    };
  },
  computed: {
    queryParams(): URLSearchParams {
      const queryParameters = new URLSearchParams();

      if (this.selectedJobFilter) {
        queryParameters.append('candidate__searched_jobs__job', this.selectedJobFilter.id.toString());
      }

      return queryParameters;
    },
  },
  watch: {
    async queryParams(newValue: URLSearchParams, oldValue: URLSearchParams): Promise<void> {
      if (newValue !== oldValue && oldValue.size > 0) {
        this.setPage(this.page);
      }
    },
  },
  async mounted(): Promise<void> {
    await this.handleCandidatesList();
    this.setResumesFilterCount(this.localCandidatesList.length + this.localNewCandidatesList.length);
  },

  methods: {
    ...mapMutations('resumeShop', [
      'setResumesFilterCount',
    ]),
    async getMatchingCandidateList(newCandidatesQuery: boolean = false): Promise<PaginatedCandidateResponse | null> {
      let response = null;

      let url = '/api/candidate/';
      const { queryParams } = this;

      queryParams.set('new_candidates', newCandidatesQuery ? '1' : '0');
      queryParams.set('page', this.page.toString());

      url += `?${queryParams.toString()}`;

      try {
        const {
          count,
          totalPages,
          pageSize,
          previousPageNumber,
          nextPageNumber,
          results,
        } = await window.ky.get(url).json<PaginatedResult<CandidateShopItem>>() as PaginatedResult<CandidateShopItem>;

        if (results) {
          response = {
            count,
            totalPages,
            pageSize,
            previousPageNumber,
            nextPageNumber,
            results,
          };
        }
      } catch {
        this.toast.error((this.$t('views.candidates.error') as string));
      }

      return response;
    },
    setPage(page: number): void {
      this.page = page;
      this.lookupAddressHandler();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getAllMatchingCandidateList(): Promise<void> {
      const normalMatchingCandidateList = await this.getMatchingCandidateList();

      if (normalMatchingCandidateList) {
        this.count = normalMatchingCandidateList.count;
        this.totalPages = normalMatchingCandidateList.totalPages;
        this.pageSize = normalMatchingCandidateList.pageSize;
        this.previousPageNumber = normalMatchingCandidateList.previousPageNumber;
        this.nextPageNumber = normalMatchingCandidateList.nextPageNumber;
        this.localCandidatesList = normalMatchingCandidateList.results.sort((a, b) => b.matchingPercentage - a.matchingPercentage);
      }
    },
    async getNewMatchingCandidateList(): Promise<void> {
      const normalMatchingCandidateList = await this.getMatchingCandidateList(true);

      if (normalMatchingCandidateList) {
        this.localNewCandidatesList = normalMatchingCandidateList.results.sort((a, b) => b.matchingPercentage - a.matchingPercentage);
      }
    },
    async handleCandidatesList(): Promise<void> {
      await this.getAllMatchingCandidateList();
      await this.getNewMatchingCandidateList();
    },
  },

});
</script>

<style scoped lang="css">
.pagination {
  justify-content: end;
  padding-right: var(--bs-gutter-x);
}

.pagination-container {
  padding-left: 0;
}
</style>
