<template>
  <div>
    <h4 class="h5">{{ $t('views.account.invoices.title') }}</h4>
    <ul class="invoices-list">
      <li v-for="(invoice, index) in invoices" :key="index" class="invoice-item">
        <v-sprite icon="invoice-pdf" />
        <div class="invoice-infos">
          <p class="number">Facture n°{{ invoice.invoiceNumber }}</p>
          <p class="date">{{ invoice.createdAt }}</p>
        </div>
        <button
          v-if="invoice.isTreated"
          type="button"
          class="btn btn-sm btn-primary"
          @click.prevent="downloadInvoice(invoice.id)">
          <v-sprite color="#fff" icon="download" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { saveAs } from 'file-saver';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

import Invoice from '../interfaces/invoice';

export default defineComponent({
  name: 'InvoicesList',
  props: {
    invoices: {
      type: Object as PropType<Invoice[]>,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async downloadInvoice(invoiceId: number): Promise<void> {
      try {
        const response = await window.ky.get(`/api/invoices/${invoiceId}/download/`);
        let filename = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('attachment')) {
          const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches && matches[1]) {
            filename = matches[1].replaceAll(/["']/g, '');
          }
        }
        const blob = await response.blob();
        saveAs(blob, filename);
      } catch {
        this.toast.error((this.$t('components.download_invoice.error') as string));
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.invoices-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  margin: 32px 0 0;
  max-width: 100%;
  padding: 0;
  width: 500px;
}

.invoice-item {
  align-items: start;
  display: flex;
  gap: 10px;

  .number {
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0;
  }

  .date {
    color: $steel;
    font-size: 14px;
    margin-bottom: 0;
  }

  .btn {
    align-items: center;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-left: auto;
    padding: 0;
    width: 34px;
  }
}
</style>
